import { defineStore } from 'pinia'

export const useFormStore = defineStore('formStore', {
  state: () => ({
    config: {
      loading: true,
    },
    errors: null,
    showLoading: false,
    dialog: false,
    dialogTitle: '',
    dialogMessage: ''
  }),

  getters: {

  },

  actions: {

  }
})
