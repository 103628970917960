export default {
    beforeMount(el, binding) {
        el.innerHTML = formatCurrency(binding.value);
    },
    updated(el, binding) {
        el.innerHTML = formatCurrency(binding.value);
    }
};

function formatCurrency(value) {
    return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
    }).format(value);
}
