// export const api_base_url = "https://apiqurban.almuhajirin-jatikramat.com/api"
// export const base_url = "https://apiqurban.almuhajirin-jatikramat.com"

export const api_base_url = "https://api.tabungsawargi.com/api"
export const base_url = "https://tabungsawargi.com"

// export const api_base_url = "http://localhost:8000/api"
// export const base_url = "http://localhost:8080"

// export const api_base_url = "https://api2.databasetempetahu.org/api"
// export const base_url = "https://tabungsawargi.com"