import { useAuthstore } from '@/stores/modules/auth'
import { createPinia } from 'pinia'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/home',
    name: 'Home',
    component: () => import('@/components/layout/TheContainer'),
    meta: {
      auth: true
    },
    children: [
      { path: 'home', name: 'home', component: () => import('@/views/Home'), meta: { requiresPermission: true, permissions: ['dashboard member', 'dashboard dist', 'dashboard agent', 'dashboard admin'] } },

      { path: 'user', name: 'user', component: () => import('@/views/User/Index'), meta: { requiresPermission: true, permissions: ['user read'] } },
      { path: 'user/create', name: 'user-create', component: () => import('@/views/User/Form'), meta: { requiresPermission: true, permissions: ['user create'] } },
      { path: 'user/:id/edit', name: 'user-edit', component: () => import('@/views/User/Form'), meta: { requiresPermission: true, permissions: ['user update'] } },

      { path: 'role', name: 'role', component: () => import('@/views/Role/Index'), meta: { requiresPermission: true, permissions: ['role read'] } },
      { path: 'role/create', name: 'role-create', component: () => import('@/views/Role/Form'), meta: { requiresPermission: true, permissions: ['role create'] } },
      { path: 'role/:id/edit', name: 'role-edit', component: () => import('@/views/Role/Form'), meta: { requiresPermission: true, permissions: ['role update'] } },

      { path: 'profile', name: 'profile', component: () => import('@/views/Profile'), meta: { requiresPermission: true, permissions: ['profile'] } },

      { path: 'denied', name: 'denied', component: () => import('@/views/Denied'), meta: { requiresPermission: false } },

      { path: "/:pathMatch(.*)*", component: () => import('@/views/NotFound'), meta: { requiresPermission: false } },
      { path: 'denied', name: 'denied', component: () => import('@/views/Denied'), meta: { requiresPermission: false } },

      { path: 'permission', name: 'permission-index', component: () => import('@/views/Permission/Index'), meta: { requiresPermission: true, permissions: ['permission read'] } },
      { path: 'permission/create', name: 'permission-create', component: () => import('@/views/Permission/Form'), meta: { requiresPermission: true, permissions: ['permission create'] } },
      { path: 'permission/:id/edit', name: 'permission-edit', component: () => import('@/views/Permission/Form'), meta: { requiresPermission: true, permissions: ['permission update'] } },

      { path: 'agent', name: 'agent-index', component: () => import('@/views/Agent/Index'), meta: { requiresPermission: true, permissions: ['agent read'] } },
      { path: 'agent/create', name: 'agent-create', component: () => import('@/views/Agent/Form'), meta: { requiresPermission: true, permissions: ['agent create'] } },
      { path: 'agent/:id/edit', name: 'agent-edit', component: () => import('@/views/Agent/Form'), meta: { requiresPermission: true, permissions: ['agent update'] } },

      { path: 'branch', name: 'branch-index', component: () => import('@/views/Branch/Index'), meta: { requiresPermission: true, permissions: ['branch read'] } },
      { path: 'branch/create', name: 'branch-create', component: () => import('@/views/Branch/Form'), meta: { requiresPermission: true, permissions: ['branch create'] } },
      { path: 'branch/:id/edit', name: 'branch-edit', component: () => import('@/views/Branch/Form'), meta: { requiresPermission: true, permissions: ['branch update'] } },

      { path: 'saving-account/accounts', name: 'saving_account-accounts', component: () => import('@/views/SavingAccount/Accounts'), meta: { requiresPermission: true, permissions: ['saving_account read'] } },
      { path: 'saving-account/transactions', name: 'saving_account_transactions-index', component: () => import('@/views/SavingAccountTransaction/Index'), meta: { requiresPermission: true, permissions: ['saving_account_transaction read'] } },
      { path: 'saving-account/transactions/need-approval', name: 'saving_account_transactions-need-approval', component: () => import('@/views/SavingAccountTransaction/NeedApproval'), meta: { requiresPermission: true, permissions: ['saving_account_transaction verification'] } },
      { path: 'saving-account/:memberId?', name: 'saving_account-index', component: () => import('@/views/SavingAccount/Index'), meta: { requiresPermission: true, permissions: ['saving_account read'] } },
      { path: 'saving-account/:id/transactions', name: 'saving_account-transactions', component: () => import('@/views/SavingAccount/Detail'), meta: { requiresPermission: true, permissions: ['saving_account read'] } },

      { path: 'saving-account/category', name: 'saving_account_category-index', component: () => import('@/views/SavingAccountCategory/Index'), meta: { requiresPermission: true, permissions: ['saving_account_category read'] } },
      { path: 'saving-account/category/create', name: 'saving_account_category-create', component: () => import('@/views/SavingAccountCategory/Form'), meta: { requiresPermission: true, permissions: ['saving_account_category create'] } },
      { path: 'saving-account/category/:id/edit', name: 'saving_account_category-edit', component: () => import('@/views/SavingAccountCategory/Form'), meta: { requiresPermission: true, permissions: ['saving_account_category edit'] } },

    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // return desired position
    window.scrollTo(0, 0)
  }
})

//MIDDLEWARE
router.beforeEach(async (to, from, next) => {
  // Function to run on every route change
  const authStore = useAuthstore()
  if (to.name != 'login') {
    await authStore.getUserPermissions()
    await authStore.getUserDetails()
  }

  if (to.meta.requiresPermission === true) {

    const permissions = authStore.permissions
    const routePermission = to.meta.permissions

    //access check
    if (
      routePermission.some(permission => permissions.includes(permission))
    ) {
      return next()
    } else {
      if (!localStorage.getItem('token')) {
        router.push({
          name: 'login'
        })
      } else {
        router.push({
          name: 'denied'
        })

      }
    }
  } else {
    return next()
  }


  next();
});



export default router
