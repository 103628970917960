<template>
  <n-config-provider :theme="theme" :theme-overrides="overrideTheme">
    <n-message-provider>
      <n-dialog-provider>
        <router-view></router-view>
      </n-dialog-provider>
    </n-message-provider>
  </n-config-provider>
</template>
<script setup>
import { defineComponent, onMounted, ref } from "vue";
import { useThemeStore } from '@/stores/modules/theme'
import { NMessageProvider, NConfigProvider, NDialogProvider } from "naive-ui";
import { useRouter } from "vue-router";

    const store = useThemeStore()
    
    const router = useRouter()
    const overrideTheme = {
      common: {
        primaryColor: '#0052CC',
        primaryColorHover: '#264a84',
        primaryColorPressed: "#"
      },
    }

    let theme = ref(store.theme)

    store.$subscribe((mutation, state) => {
      theme.value = state.theme
    })

</script>
