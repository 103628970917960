import { darkTheme } from 'naive-ui'
import { defineStore } from 'pinia'

export const useThemeStore = defineStore('theme', {
    state: () => ({
        theme: localStorage.getItem('theme') == 'dark' ? darkTheme : null
    }),
    actions: {
        setTheme() {
            const theme = this.theme == null ? 'dark' : null
            localStorage.setItem('theme', theme)
            this.theme = theme ? darkTheme : null
        }
    }
})