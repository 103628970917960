import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import components from './plugin/globalComponent'
import './assets/css/bootstrap/bootstrap.min.css'
import './assets/css/main.css'
import axios from './plugin/axios'
import VueAxios from 'vue-axios'
import HeaderTitle from '@/components/layout/HeaderTitle.vue'
import vueDebounce from 'vue-debounce'
import { createPinia } from 'pinia'
import {can, canDo} from '@/directives/can.js'
import currencyFormat from '@/directives/currencyFormat'



const pinia = createPinia()

document.title = 'Dashboard'

createApp(App)
.directive('debounce', vueDebounce({ lock: true }))
.directive('can', can)
.directive('currency-format', currencyFormat)
.use(pinia)
.use(router)
.use(components)
.use(VueAxios, axios)
.component('header-title', HeaderTitle)
.mount('#app')