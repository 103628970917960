import { useAuthstore } from "@/stores/modules/auth";

export const can = (el, binding, vnode) => {
    const authStore = useAuthstore()
	const permissions = authStore.permissions;

    if(permissions.includes(binding.value))
    {
        return vnode.el.hidden = false;
    }
    else
    {
        return vnode.el.hidden = true;
    }

}

export const canDo = (permission) => {
    const authStore = useAuthstore()
	const permissions = authStore.permissions;

    return permissions.includes(permission)
}