import axios from 'axios'
import Router from "@/router"
import { useMessage } from 'naive-ui'
import { api_base_url } from '@/constants/config'
import { useFormStore } from '@/stores/useFormStore'

const BACKEND_API_URL = api_base_url

// console.log(BACKEND_API_URL)

axios.defaults.baseURL = BACKEND_API_URL
axios.defaults.headers.common['Lang'] = 'en'
axios.defaults.headers.common['Content-Type'] = 'application/json'
if (localStorage.getItem('token')?.length > 0) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
}
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = ['*']

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {

        const formStore = useFormStore()

        if (!error.response) {
            // console.log(error)
            return Promise.reject(error)
        }

        if (error.response.status == 401 && !window.location.pathname.includes('login')) {
            localStorage.removeItem('token')

            Router.push('/login')
        } else if (error.response.status == 403) {
            Router.push('/home')
        } else if (error.response.status == 422) {
            formStore.errors = error.response.data.errors
        }

        formStore.showLoading = false
        return Promise.reject(error)
    }
);


export default axios