
import axios from 'axios'
import { defineStore } from 'pinia'

export const useAuthstore = defineStore('auth', {
    state: () => ({
        user: {},
        permissions: []
    }),
    actions: {
        async login(payload) {
            try {
                const res = await axios.post('/login', payload)
                
                // localStorage.setItem('user', JSON.stringify(payload))

                localStorage.setItem('token', res.data.data.access_token)
                return res
            } catch (error) {
                return error.response
            }
        },
        async getUserPermissions() {
            try {
                const res = await axios.get('roles/get_permissions?specific=true')

                this.permissions = res.data.data
                return res.data.data
            } catch (error) {
                console.log(error)
            }
        },
        async getUserDetails() {
            try {
                const res = await axios.get('users/detail')

                this.user = res.data.data
            } catch (error) {
                console.log(error)
            }
        }
    }
})
